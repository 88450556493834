<script setup lang="ts">
const email = ref('')
const formSubmitted = ref(false)

async function sendForm() {
  const res = await $fetch('/contact/request', {
    method: 'POST',
    body: JSON.stringify({
      to: 'marketing',
      email: email.value,
      message: 'Stay in the know',
    }),
  })
  formSubmitted.value = true
}

const links = [
  [
    {
      name: 'Token Designer',
      href: webflowLink('/tools#token-designer'),
    },
    {
      name: 'Tokenomics',
      href: webflowLink('/tools#tokenomics'),
    },
    {
      name: 'AutoDistributions',
      href: webflowLink('/tools#autodistribution'),
    },
    {
      name: 'AMM<sup>2</sup>',
      href: webflowLink('/tools#amm2'),
    },
  ],
  [
    {
      name: 'Full-Service Market Maker',
      href: webflowLink('/tools#full-service-market-making'),
    },
    {
      name: 'Business Intelligence',
      href: webflowLink('/tools#business-intelligence'),
    },
    {
      name: 'Listing & Liquidity',
      href: webflowLink('/tools#listing-and-liquidity'),
    },
    {
      name: 'Growth Capital',
      href: webflowLink('/tools#growth-capital'),
    },
  ],
]

const subLinks = [
  {
    name: 'What is Forgd',
    href: webflowLink('/about/'),
  },
  {
    name: 'Fees & Pricing',
    href: webflowLink('/pricing/'),
  },
  {
    name: 'Contact us',
    href: webflowLink('/contact/'),
  },
  {
    name: 'FAQs',
    href: 'https://forgd.gitbook.io/explore/faqs',
    external: true,
  },
  {
    name: `
      <div class="flex space-x-1">
        <span>Forgd on GitBook</span>
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4688 2.28125L16.625 2.28125C16.7825 2.28125 16.9335 2.34381 17.0448 2.45516C17.1562 2.56651 17.2188 2.71753 17.2188 2.875V7.03125C17.2188 7.35917 16.9529 7.625 16.625 7.625C16.2971 7.625 16.0312 7.35917 16.0312 7.03125V4.30844L6.35734 13.9823C6.12547 14.2142 5.74953 14.2142 5.51766 13.9823C5.28578 13.7505 5.28578 13.3745 5.51766 13.1427L15.1916 3.46875L12.4688 3.46875C12.1408 3.46875 11.875 3.20292 11.875 2.875C11.875 2.54708 12.1408 2.28125 12.4688 2.28125ZM4.15625 5.84375C3.50041 5.84375 2.96875 6.37541 2.96875 7.03125V15.3438C2.96875 15.9996 3.50041 16.5312 4.15625 16.5312H12.4688C13.1246 16.5312 13.6562 15.9996 13.6562 15.3438V8.8125C13.6562 8.48458 13.9221 8.21875 14.25 8.21875C14.5779 8.21875 14.8438 8.48458 14.8438 8.8125V15.3438C14.8438 16.6554 13.7804 17.7188 12.4688 17.7188H4.15625C2.84457 17.7188 1.78125 16.6554 1.78125 15.3438V7.03125C1.78125 5.71957 2.84457 4.65625 4.15625 4.65625H10.6875C11.0154 4.65625 11.2812 4.92208 11.2812 5.25C11.2812 5.57792 11.0154 5.84375 10.6875 5.84375H4.15625Z" fill="#001221"/>
        </svg>
      </div>
    `,
    href: 'https://forgd.gitbook.io/explore/',
    external: true,
  },
  {
    name: `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4522_5422)">
        <path d="M14.8189 0H1.18111C0.867861 0 0.567441 0.124438 0.34594 0.34594C0.124438 0.567441 0 0.867861 0 1.18111V14.8189C0 15.1321 0.124438 15.4326 0.34594 15.6541C0.567441 15.8756 0.867861 16 1.18111 16H14.8189C15.1321 16 15.4326 15.8756 15.6541 15.6541C15.8756 15.4326 16 15.1321 16 14.8189V1.18111C16 0.867861 15.8756 0.567441 15.6541 0.34594C15.4326 0.124438 15.1321 0 14.8189 0ZM4.76889 13.63H2.36333V5.98889H4.76889V13.63ZM3.56445 4.93C3.29158 4.92846 3.02528 4.84613 2.79916 4.69339C2.57304 4.54065 2.39723 4.32435 2.29392 4.07179C2.19061 3.81923 2.16443 3.54173 2.21869 3.2743C2.27294 3.00688 2.4052 2.76152 2.59877 2.56919C2.79234 2.37686 3.03854 2.24618 3.30631 2.19364C3.57408 2.1411 3.85141 2.16906 4.1033 2.27399C4.35519 2.37892 4.57036 2.55611 4.72164 2.78321C4.87293 3.01031 4.95355 3.27713 4.95333 3.55C4.95591 3.73269 4.92167 3.91403 4.85267 4.0832C4.78368 4.25238 4.68132 4.40593 4.55171 4.53471C4.42211 4.66349 4.2679 4.76486 4.09828 4.83277C3.92867 4.90068 3.74711 4.93375 3.56445 4.93ZM13.6356 13.6367H11.2311V9.46222C11.2311 8.23111 10.7078 7.85111 10.0322 7.85111C9.31889 7.85111 8.61889 8.38889 8.61889 9.49333V13.6367H6.21333V5.99445H8.52667V7.05333H8.55778C8.79 6.58333 9.60333 5.78 10.8444 5.78C12.1867 5.78 13.6367 6.57667 13.6367 8.91L13.6356 13.6367Z" fill="#001221"/>
        </g>
        <defs>
        <clipPath id="clip0_4522_5422">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    `,
    href: 'https://linkedin.com/company/forgd-by-solana/',
    external: true,
  },
  {
    name: `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.03168 14.5003C11.0694 14.5003 14.3718 9.4981 14.3718 5.16018C14.3718 5.0181 14.3718 4.87666 14.3622 4.73586C15.0047 4.27117 15.5593 3.69579 16 3.03666C15.4009 3.30227 14.7654 3.47637 14.1146 3.55314C14.7999 3.14294 15.3128 2.49767 15.5578 1.73746C14.9134 2.11987 14.2084 2.38935 13.4733 2.53426C12.9783 2.00798 12.3237 1.65949 11.6108 1.54272C10.8978 1.42595 10.1663 1.54741 9.52931 1.8883C8.89234 2.22919 8.38548 2.77051 8.08716 3.4285C7.78884 4.08648 7.71569 4.82444 7.87904 5.52818C6.57393 5.46272 5.29717 5.12354 4.13164 4.53267C2.9661 3.9418 1.93784 3.11244 1.1136 2.09842C0.693819 2.82109 0.565248 3.67658 0.754066 4.49071C0.942885 5.30484 1.43489 6.01639 2.12992 6.4805C1.60749 6.4652 1.09643 6.32426 0.64 6.06962V6.11122C0.640207 6.86912 0.902567 7.60362 1.38258 8.19014C1.86259 8.77665 2.53071 9.17907 3.2736 9.32914C2.79032 9.46097 2.28325 9.48024 1.79136 9.38546C2.00121 10.0377 2.40962 10.608 2.95949 11.0168C3.50937 11.4255 4.17322 11.6522 4.85824 11.6651C4.17763 12.2001 3.39821 12.5957 2.56458 12.8291C1.73096 13.0626 0.859476 13.1294 0 13.0258C1.50122 13.9891 3.24795 14.5001 5.03168 14.4978" fill="#001221"/>
      </svg>
    `,
    href: 'https://x.com/forgd_',
    external: true,
  },
]
</script>

<template>
  <div class="bg-forgd-bgd-200">
    <UiRule />

    <div class="py-20 max-w-forgd mx-auto px-6">
      <div class="flex flex-col-reverse lg:flex-row justify-between">
        <div class="max-w-md">
          <div class="flex space-x-2">
            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20.0002" cy="32.5" r="4.8" fill="#2D73FF" />
              <path d="M30 17.8335C30 15.1813 28.9464 12.6378 27.0711 10.7624C25.1957 8.88706 22.6522 7.8335 20 7.8335C17.3478 7.8335 14.8043 8.88706 12.9289 10.7624C11.0536 12.6378 10 15.1813 10 17.8335C10 29.5002 5 32.8335 5 32.8335H35C35 32.8335 30 29.5002 30 17.8335Z" stroke="#001221" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <line x1="20.75" y1="5.25" x2="20.75" y2="9.75" stroke="#001221" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <h2 class="text-forgd-primary-900 text-4xl font-normal font-sora">
              Stay in the know
            </h2>
          </div>

          <p class="text-base font-normal text-gray-600 mt-4">
            Shoot us your email to stay up to date with the latest offerings news from Forgd
          </p>

          <div class="mt-8">
            <label for="email-newsletter" class="text-forgd-primary-900 text-sm font-normal mb-4">Your email</label>
            <div class="flex items-center justify-between border border-forgd-600 bg-white px-4 py-3 rounded-lg">
              <input
                id="email-newsletter"
                v-model="email"
                class="w-full border-0 placeholder:font-normal placeholder:text-forgd-400 placeholder:text-xl text-xl focus:outline-0 focus:outline-none focus:shadow-none focus:ring-0"
                placeholder="Enter your email"
                type="email"
              >
              <div class="text-forgd-700 flex items-center">
                <svg v-if="!formSubmitted" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" @click="sendForm">
                  <path d="M17.5 10.5001H2.5H17.5Z" fill="#1A54C4" />
                  <path d="M11.6667 4.66675L17.5 10.5001M17.5 10.5001L11.6667 16.3334M17.5 10.5001H2.5" stroke="#AEAEA4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <UIcon v-else name="i-heroicons-check-circle" class="w-6 h-6 text-forgd-primary-300" />
              </div>
            </div>
          </div>
        </div>

        <div class="mb-12 lg:mb-0">
          <h2 class="text-forgd-primary-900 text-4xl font-normal font-sora mb-12 ">
            Forgd Tools &amp; Services
          </h2>

          <div class="flex justify-between space-x-40">
            <div v-for="(linkGroup, i) in links" :key="i" class="space-y-3">
              <div v-for="(link, j) in linkGroup" :key="j">
                <NuxtLink :to="link.href" class="text-forgd-gray-600 text-base font-normal hover:text-forgd-primary-300 hover:underline">
                  <span v-html="link.name" />
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <UiRule />

    <div class="py-8 lg:flex lg:items-center lg:justify-between max-w-7xl mx-auto px-6">
      <div class="flex items-center justify-center space-x-9 text-black mb-5 lg:mb-0">
        <NuxtLink
          v-for="(link, j) in subLinks"
          :key="j"
          :to="link.href"
          :target="link.external ? '_blank' : '_self'"
          class="text-forgd-primary-900 text-sm font-normal hover:text-forgd-primary-300 hover:underline"
        >
          <span v-html="link.name" />
        </NuxtLink>
      </div>

      <div class="text-xs text-center">
        &copy; {{ new Date().getFullYear() }} Forgd. All rights reserved.
        <NuxtLink :to="webflowLink('/terms-of-use/')" class="underline text-forgd-primary-300 font-semibold">
          Terms & Conditions
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
